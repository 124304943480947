import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ShutterstockPhotoAgents from '../images/uploads/Agents_shutterstock_1096209995.jpg';

const Agents = () => {
  return (
    <Layout pageSlug="agents">
      <SEO title="Agents" />

      <div className="row bg-grey bg-marque">
        <section className="row header">
          <div className="container">
            <div className="row content">
              <div className="col l24 m24 s24">
                <div className="box bs header-full-image">
                  <div className="page-heading">
                    <h3>Agents</h3>
                  </div>
                  <div className="inner-margin-top-minus-60">
                    <img src={ShutterstockPhotoAgents} alt="Agents" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row padding-bottom-30">
          <div className="container">
            <div className="row">
              <div className="col l16 m16 s24">
                <div className="box bs">
                  <h2>
                    Whatever your needs,
                    <br />
                    wherever you are – we’re here.
                  </h2>
                </div>
              </div>
            </div>
            <div className="row padding-bottom-10">
              <div className="col l12 m16 s24 offset-l2 offset-m2 offset-s0">
                <div className="box bs content-block">
                  <p>
                    If you are an educational agent who places students in
                    international education, or a company who provides services
                    for international students (e.g. Guardianship,
                    Accommodation) you can also make use of our services. You
                    can accept payment from your overseas clients in multiple
                    currencies to make paying you as simple as possible and your
                    client will benefit from the best exchange rates and lowest
                    fees.
                  </p>
                  <p>
                    Our service is free of charge to the Agent for receiving
                    payments and you will also be able to make outbound
                    international payments at far lower fees than your bank
                    would charge.
                  </p>
                  <p>
                    If your company also offers Immigration services (worldwide
                    to any country) we can send your client&rsquo;s funds
                    internationally at the best rates ensuring they get the most
                    currency for their money.
                  </p>
                  <p>
                    Please see our&nbsp;
                    <a
                      href="https://www.121payments.com/immigration/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Immigration section
                    </a>
                    .
                  </p>
                  <p>
                    <strong>
                      All transactions take place via our FCA registered and
                      regulated partner for your financial security.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row bg-purple padding-top-40 padding-bottom-60">
          <div className="container">
            <div className="row">
              <div className="col l24 m24 s24">
                <div className="box bs">
                  <h3>How can we help?</h3>

                  <div className="btn-inline">
                    <Link
                      className="btn btn-primary animated-arrow"
                      to="/contact-us"
                    >
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> Enquire Now </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                    <Link className="btn btn-primary animated-arrow" to="/">
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> Customer Login </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                    <Link className="btn btn-primary animated-arrow" to="/">
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> Register Company </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Agents;
